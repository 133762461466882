import cx from 'classnames'

import { SanityLogoGridModule } from '@data/sanity/queries/types/modules'

import Photo from '@components/photo'

type LogoGridProps = Pick<SanityLogoGridModule, 'title' | 'logos'>

const LogoGrid = ({ title, logos }: LogoGridProps) => (
  <section className="container py-7 md:py-10">
    {title && <h5 className="text-center mb-5">{title}</h5>}

    <div
      className={cx(
        'flex flex-wrap items-center justify-center gap-x-4 gap-y-2 sm:gap-x-12 xl:gap-x-14',
      )}
    >
      {logos.map((logo) => {
        return (
          <Photo
            key={logo._key}
            image={logo}
            className="flex items-center justify-center p-2 w-32 sm:w-44 xl:w-64"
          />
        )
      })}
    </div>
  </section>
)

export default LogoGrid
